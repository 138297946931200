.info-form-danger {
  position: absolute;
  font-weight: bold;
  color: rgb(255, 0, 0);
  right: 0;
  transform: translateX(180%);
}
.info-form-success {
  position: absolute;
  font-weight: bold;
  color: rgb(10, 171, 10);
  right: 0;
  transform: translateX(180%);
}

.material-textfield {
  position: relative;
  margin-bottom: 200px;
}

.label-input {
  position: absolute;
  font-size: 0.8rem;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  background-color: white;
  color: gray;
  padding: 0 0.3rem 0 10px;
  margin: -0.3rem 0.8rem;
  transition: 0.1s ease-out;
  transform-origin: left top;
  pointer-events: none;
}

.otp-input {
  font-size: 1rem;
  border: none;
  transition: 0.1s ease-out;
  outline: none;
  width: 12%;
  margin-right: 5px;
  padding: 5px 0;
  text-align: center;
  border-radius: 20px;
  color: white;
  background-color: #ff6868;
  font-weight: bold;
}

.otp-input:focus {
  border-color: red;
}

.form-input {
  width: 100%;
  padding: 10px 15px;
  border: 1px solid #ccc;
  border-radius: 150px;
  outline: none;
  font-size: 1rem;
  margin-bottom: 8px;
}

.form-input:focus {
  padding: 10px 21px;
  border-color: red;
}

.form-input:focus + .label-input {
  color: red;
  top: 0;
  padding: 0 3px;
  position: absolute;
  left: 8px;
  transform: translateY(-20%) scale(0.9) !important;
}

.form-input:not(:placeholder-shown) + label {
  left: 8px;
  padding: 0 3px;
  top: 0;
  transform: translateY(-20%) scale(0.9) !important;
  background-color: white;
}
.form-input:not(:placeholder-shown) {
  padding-left: 21px;
}

.form-input:not(:focus)::placeholder {
  opacity: 0;
}

.success-button {
  width: 100%;
  padding: 5px;
  border: 2px solid #ff4d4d;
  border-radius: 50px;
  background-color: #ff4d4d;
  color: #fff;
  cursor: pointer;
}

.failed-button {
  width: 100%;
  padding: 5px;
  border: 2px solid #ff4d4d;
  border-radius: 50px;
  background-color: white;
  color: #ff4d4d;
  cursor: pointer;
}
.login-container form {
  width: 100%;
}
.login-container form div {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.login-container form label {
  margin-bottom: 5px;
}
.login-container {
  min-width: 350px;
  max-width: 400px;
  margin: 0 auto;
  padding: 50px;
  background-color: rgb(255, 255, 255);
  border-radius: 50px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  justify-content: center;
  margin-top: 100px;
}

.login-container p {
  margin-top: 10px;
  text-align: center;
}
.header-text {
  font-size: 23px;
  font-weight: bolder;
  width: 100%;
}
.under-header-text {
  font-size: 12px;
  font-weight: thin;
  margin-top: -20px;
  margin-bottom: 24px;
  width: 100%;
}
