.logo {
  height: 30px;
  width: auto;
  justify-content: center;
}

.label-form {
  font-weight: bold;
}
.input-container {
  position: relative;
}

.password-icon {
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-60%);
  cursor: pointer;
}

body {
  background-color: red;
}
.link {
  color: red;
  text-decoration: none;
}
.textfield-input {
  border-radius: 50px;
}
