.logo {
  height: 30px;
  width: auto;
  justify-content: center;
}
.login-container form {
  width: 100%;
}
.header-text {
  font-size: 23px;
  font-weight: bolder;
}
.under-header-text {
  font-size: 12px;
  font-weight: thin;
  margin-top: -20px;
  margin-bottom: 24px;
}

.login-container form div {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.login-container form label {
  margin-bottom: 5px;
}

.label-form {
  font-weight: bold;
}
.input-container {
  position: relative;
}

.password-icon {
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-60%);
  cursor: pointer;
}

.login-container p {
  margin-top: 10px;
  text-align: center;
}
body {
  background-color: red;
}
.link {
  color: red;
  text-decoration: none;
}
