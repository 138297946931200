.logo {
  height: 30px;
  width: auto;
  justify-content: center;
}
.logo-top {
  /* margin: -90px 0 0 50%; */
  top: 4em;
  left: 50%;
  transform: translateX(-50%);
  justify-content: center;
  width: 100px;
  position: absolute;
}

.input-phone {
  width: 100%;
  padding: 20px 30px;
  border: 1px solid #ccc;
  border-radius: 150px;
  font-size: 12px;
  margin-bottom: 8px;
}

.label-form {
  font-weight: bold;
}
.input-container {
  position: relative;
}

.password-icon {
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-60%);
  cursor: pointer;
}

.id-phone {
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-70%);
  font-size: 12px;
  cursor: default;
}

.login-container p {
  margin-top: 10px;
  text-align: center;
}
body {
  background-color: red;
}
.link {
  color: red;
  text-decoration: none;
}
