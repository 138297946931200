.main-container {
  width: 80%;
  min-width: 390px;
  margin: 0 auto;
  padding: 10px;
  margin-top: 80px;
}

.base-container {
  display: flex;
  justify-content: center;
}
.parent-container {
  width: 100%;
  padding: 12px;
  background-color: rgb(255, 255, 255);
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}
.under-line {
  border-bottom: 1px solid rgb(201, 201, 201);
}
.parent-header {
  padding: 3px;
  font-weight: bold; 
  font-size: 16px;
}
.child-header {
  padding: 3px;
  font-size: 10px;
}

.carousel-image {
  margin-left: 50%;
  transform: translateX(-50%);
  width: 100%;
  height: 150;
  object-fit: contain;
  position: relative;
}

.carousel-container {
  width: 100%;
  padding: 12px;
  margin-bottom: 5%;
  height: 150;
  z-index: 0;
}
